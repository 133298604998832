import { ApolloProvider as Apollo } from '@apollo/client/react/context/ApolloProvider';
import client from '@fingo/lib/apollo';
import PropTypes from 'prop-types';
import React from 'react';

const ApolloProvider = ({ children }) => (
  <Apollo client={client}>{children}</Apollo>
);

ApolloProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApolloProvider;
