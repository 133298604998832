import 'react-app-polyfill/stable';

import TranslationProvider from '@fingo/lib/components/translation/TranslationProvider';
import SnackbarAlert from '@fingo/lib/contexts/snack-bar';
import PropTypes from 'prop-types';
import React from 'react';
import { render } from 'react-dom';
import App from './App';
import ApolloProvider from './providers/ApolloProvider';
import GoogleTagManagerProvider from './providers/GoogleTagManagerProvider';
import IntercomProvider from './providers/IntercomProvider';
import MuiProvider from './providers/MuiProvider';
import UtmCampaignProvider from './providers/UtmCampaignProvider';

const GlobalProviders = ({ children }) => (
  <MuiProvider>
    <ApolloProvider>
      <GoogleTagManagerProvider>
        <IntercomProvider>{children}</IntercomProvider>
      </GoogleTagManagerProvider>
    </ApolloProvider>
  </MuiProvider>
);

GlobalProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

const ContextProviders = ({ children }) => (
  <TranslationProvider>
    <UtmCampaignProvider>
      <SnackbarAlert />
      {children}
    </UtmCampaignProvider>
  </TranslationProvider>
);

ContextProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

const Fingo = () => (
  <React.StrictMode>
    <GlobalProviders>
      <ContextProviders>
        <App />
      </ContextProviders>
    </GlobalProviders>
  </React.StrictMode>
);

render(<Fingo />, document.getElementById('root'));
