import { lazy } from '@loadable/component';

const Game = lazy(() => import(/* webpackChunkName: "game" */ './game/index'));
const App = lazy(() => import(/* webpackChunkName: "app" */ './views/App/App'));
const AutomaticCession = lazy(() => import(/* webpackChunkName: "automatic-cession" */ './views/AutomaticCession'));
const ChangeAssignmentCessionType = lazy(() => import(/* webpackChunkName: "change-assignment-cession-type" */ './views/ChangeAssignmentCessionType'));
const Landing = lazy(() => import(/* webpackChunkName: "landing" */ './views/Landing'));
const PasswordRecovery = lazy(() => import(/* webpackChunkName: "password-recovery" */ './views/PasswordRecovery'));
const PreOfferEvaluation = lazy(() => import(/* webpackChunkName: "pre-offer-evaluation" */ './views/PreOfferEvaluation'));
const QuickRegistration = lazy(() => import(/* webpackChunkName: "quick-registration" */ './views/QuickRegistration'));
const TermsAndConditions = lazy(() => import(/* webpackChunkName: "terms-and-conditions" */ './views/TermsAndConditions'));
const Login = lazy(() => import(/* webpackChunkName: "login" */ './views/Login'));
const OnboardingAuth = lazy(() => import(/* webpackChunkName: "onboarding-auth" */ './views/App/supplierAutomate/onboarding/SupplierOnboardingAuth'));
const EmailVerification = lazy(() => import(/* webpackChunkName: "email-verification" */ './views/EmailVerification'));
const OTPLogin = lazy(() => import(/* webpackChunkName: "otp-login" */ './views/OTPLogin'));
const ExternalLoginLanding = lazy(() => import(/* webpackChunkName: "external-login-landing" */ './features/landings/views/ExternalLoginLanding'));
const LokalCredentialsFlipCard = lazy(() => import(/* webpackChunkName: "lokal-credentials-flip-card" */ './components/pilots/LokalCredentialsFlipCard'));
const LandingRegisterForm = lazy(() => import(/* webpackChunkName: "landing-register-form" */ './components/pilots/LandingRegisterForm'));
const SimulatorRegister = lazy(() => import(/* webpackChunkName: "simulator-register" */ './components/pilots/SimulatorRegister'));
const KobrosLandingRegisterForm = lazy(() => import(/* webpackChunkName: "kobros-landing-register-form" */ './components/pilots/KobrosLandingRegisterForm'));
const LandingNPS = lazy(() => import(/* webpackChunkName: "landing-nps" */ './components/pilots/LandingNPS'));
const EvaluateInvoices = lazy(() => import(/* webpackChunkName: "evaluate-invoices" */ './components/pilots/EvaluateInvoices'));
const LandingRegisterWhatsappEvaluation = lazy(() => import(/* webpackChunkName: "landing-register-whatsapp-evaluation" */ './components/pilots/LandingRegisterWhatsappEvaluation'));
const CollectionRegister = lazy(() => import(/* webpackChunkName: "collection-register" */ './features/collection/pages/CollectionRegister'));
const PartnerLoginLanding = lazy(() => import(/* webpackChunkName: "partner-login-landing" */ './features/landings/views/PartnerLoginLanding'));
const PromoOperationLanding = lazy(() => import(/* webpackChunkName: "promo-operation-landing" */ './features/landings/views/PromoOperationLanding'));
const UserRegister = lazy(() => import(/* webpackChunkName: "user-register" */ './features/landings/views/UserRegister'));
const UserLogin = lazy(() => import(/* webpackChunkName: "user-login" */ './features/landings/views/UserLogin'));
const SimulatorRegisterVideoLanding = lazy(() => import(/* webpackChunkName: "simulator-register-video-landing" */ './features/landings/views/SimulatorRegisterVideoLanding'));

const appRoutes = [
  { path: '/app', component: App, private: true },
  { path: '/teuber', component: Game, private: true },
  { path: '/register', component: Login },
  { path: '/quick-registration', component: QuickRegistration },
  { path: '/login', component: Login },
  { path: '/provider-login', component: OnboardingAuth },
  { path: '/preoffer-evaluation/:preofferHash', component: PreOfferEvaluation },
  {
    path: '/change-assignment-cession-type/:invoiceHash',
    component: ChangeAssignmentCessionType,
  },
  {
    path: '/automatic-cession-config/:automaticCessionConfigHash',
    component: AutomaticCession,
  },
  { path: '/external-login', component: ExternalLoginLanding },
  { path: '/', component: Landing },
  { path: '/terms', component: TermsAndConditions },
  { path: '/password-recovery', component: PasswordRecovery },
  {
    path: '/email-verification/:encodedEmail/:token',
    component: EmailVerification,
  },
  { path: '/otp-verification/:clientId', component: OTPLogin },
  {
    path: '/landing-register',
    component: LandingRegisterForm,
  },
  {
    path: '/landing-register-whatsapp',
    component: LandingRegisterWhatsappEvaluation,
  },
  {
    path: '/lokal-providers',
    component: LokalCredentialsFlipCard,
  },
  {
    path: '/simulator-register',
    component: SimulatorRegister,
  },
  {
    path: '/simulator-register-video',
    component: SimulatorRegisterVideoLanding,
  },
  {
    path: '/simulator-register-campaign',
    component: SimulatorRegister,
  },
  {
    path: '/kobros-register',
    component: CollectionRegister,
  },
  {
    path: '/collection-register',
    component: KobrosLandingRegisterForm,
  },
  {
    path: '/evaluate',
    component: LandingNPS,
  },
  {
    path: '/evaluate-invoices',
    component: EvaluateInvoices,
  },
  {
    path: '/promo-operation',
    component: PromoOperationLanding,
  },
  {
    path: '/partner-login',
    component: PartnerLoginLanding,
  },
  {
    path: '/user-register',
    component: UserRegister,
  },
  {
    path: '/user-login',
    component: UserLogin,
  },
];

export default appRoutes;
